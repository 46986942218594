import styled, { css } from 'styled-components';

import { media } from './../../common/MediaQueries';
import { WysiwygStyled } from './../Wysiwyg/styles';

const BlogPostHeroStyled = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;

  @media ${media.phone} {
    margin-bottom: 4rem;
  }
`;

interface BlogPostTitleStyled {
  hasImage?: boolean;
}

const BlogPostTitleStyled = styled.h1<BlogPostTitleStyled>`
  width: 100%;
  margin-bottom: 1.5rem;

  font-size: 32px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 1;
  font-family: ${({ theme }) => theme.fontFamilies.proximaNova};

  ${({ hasImage }) =>
    !hasImage &&
    css`
      padding-top: 20px;
    `}

  @media ${media.phone} {
    margin-bottom: 3rem;
    font-size: 50px;
  }
`;

interface BlogPostContentStyledProps {
  narrow?: boolean;
}

const BlogPostContentStyled = styled.div<BlogPostContentStyledProps>`
  font-size: 16px;

  margin-bottom: 1.5rem;

  @media ${media.phone} {
    margin-bottom: 3rem;
    font-size: 18px;
  }
`;

interface BlogPostStyledProps {
  narrow?: boolean;
}

const BlogPostStyled = styled.div<BlogPostStyledProps>`
  width: 100%;

  ${({ narrow }) =>
    narrow &&
    css`
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    `}

  ${WysiwygStyled} {
    h1:not([class]),
    h2:not([class]),
    h3:not([class]),
    h4:not([class]),
    h5:not([class]),
    h6:not([class]) {
      color: ${({ theme }) => theme.colors.watermelon};
      * {
        color: ${({ theme }) => theme.colors.watermelon};
      }
    }
  }
`;

export { BlogPostStyled, BlogPostTitleStyled, BlogPostHeroStyled, BlogPostContentStyled };
