import React from 'react';

import {
  BlogPostStyled,
  BlogPostHeroStyled,
  BlogPostTitleStyled,
  BlogPostContentStyled,
} from './styles';
import Wysiwyg from './../Wysiwyg';
import Img from './../Image';
import IStrapiBlogPost from '../../interfaces/IStrapiBlogPost';

interface IProps {
  post: IStrapiBlogPost;
}

const BlogPost: React.FC<IProps> = ({ post }) => {
  const disableImage = post.seo.title.indexOf('Young startup inspiring') !== -1;

  return (
    <BlogPostStyled narrow>
      {!disableImage && (
        <BlogPostHeroStyled>
          <Img fluid={['', post.featuredImage.localFile]} alt={`${post.seo.title} image`} />
        </BlogPostHeroStyled>
      )}
      <BlogPostTitleStyled
        hasImage={!disableImage}
        dangerouslySetInnerHTML={{ __html: post.seo.title }}
      />
      <BlogPostContentStyled>
        <Wysiwyg wide mediumType>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </Wysiwyg>
      </BlogPostContentStyled>
    </BlogPostStyled>
  );
};

export default BlogPost;
