import React from 'react';
import { graphql } from 'gatsby';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Inner from '../components/Inner';
import ContentWrapper from '../components/ContentWrapper';
import Container from '../components/Container';
import BlogPost from '../components/BlogPost';
import CtaSection from '../components/CtaSection';
import IStrapiBlogPost from '../interfaces/IStrapiBlogPost';
import IStrapiBlogPostPage from '../interfaces/IStrapiBlogPostPage';

interface Props {
  data: {
    strapiBlogPosts: IStrapiBlogPost;
    strapiBlogPostPage: IStrapiBlogPostPage;
  };
}

export const BlogPostPage = ({ data }: Props) => {
  const { strapiBlogPosts: postData, strapiBlogPostPage: pageData } = data;

  const { slug, seo, featuredImage, createdAt, updatedAt } = postData;

  const {
    ctaImage,
    ctaFirstButtonText,
    ctaFirstButtonUrl,
    ctaHeadingBase,
    ctaHeadingHighlight,
    ctaSecondButtonText,
    ctaSecondButtonUrl,
    ctaSubheading,
  } = pageData;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: seo.title,
    image: [featuredImage.url],
    datePublished: createdAt,
    dateModified: updatedAt,
  };

  return (
    <Layout headerLocation="blog" footerMobileMargin={30} footerBorderOnMobile={true}>
      <Seo
        title={`${seo.title} | Flatworld.co`}
        description={seo.description}
        url={`/blogs/${slug}/`}
        image={seo.image?.url}
        twitterImage={seo.twitterImage?.url}
        schemaMarkup={schema}
      />
      <ContentWrapper isBlue>
        <Container
          zIndex={['1', '1', 'auto']}
          bg={['white', 'white', 'transparent']}
          pb={['50px', '50px', '0px']}
        >
          <Inner isBlog bg="white" isRelative isSmall>
            <Section ptBody={['20px', '25px']}>
              <BlogPost post={postData} />
            </Section>
          </Inner>
        </Container>
        <Container pt={['220px', '180px', '0px']} pb={['0', '86px']}>
          <Inner noOffsetMobile>
            <Section ptBody={['', '2.5rem', '0px']}>
              <CtaSection
                isAlt
                heading={`<p>${ctaHeadingBase} <mark>${ctaHeadingHighlight}</mark></p>`}
                subheading={ctaSubheading}
                image={['', ctaImage.localFile]}
                leftButtonUrl={ctaFirstButtonUrl}
                leftButtonText={ctaFirstButtonText}
                leftButtonTarget="_blank"
                rightButtonUrl={ctaSecondButtonUrl}
                rightButtonText={ctaSecondButtonText}
              />
            </Section>
          </Inner>
        </Container>
      </ContentWrapper>
    </Layout>
  );
};

export default BlogPostPage;

export const query = graphql`
  query ($id: String) {
    strapiBlogPosts(id: { eq: $id }) {
      seo {
        title
        description
      }
      content
      featuredImage {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, width: 900, quality: 80, formats: [AUTO, WEBP])
          }
        }
      }
      createdAt
      slug
    }
    strapiBlogPostPage {
      ctaFirstButtonText
      ctaFirstButtonUrl
      ctaSecondButtonText
      ctaSecondButtonUrl
      ctaHeadingBase
      ctaHeadingHighlight
      ctaSubheading
      ctaImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, width: 900, quality: 80, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;
